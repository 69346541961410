import React, { useState, useEffect } from "react";
import cn from "classnames";

import { ReactComponent as DropdownIcon } from "../../../media/icons/listing-dropdown-arrow.svg";
import Title from "../title";
import styles from "./dropdown.module.css";

const Dropdown = ({
  isRequiredText,
  children,
  title,
  isRequired,
  isNotEmpty,
  isActiveDropDown,
  isShowArrow = true,
  description,
  handleMouseMove,
  handleMouseLeave,
  handleTouchStart,
  handleTouchEnd
}) => {
  const [isActive, setIsActive] = useState(false);
  const bodyClassName = isActive ? styles.bodyActive : "";
  const iconClassName = isActive ? styles.iconActive : "";

  const toggleDropDown = () => {
    if (!isActiveDropDown) {
      setIsActive(prev => !prev);
    }
  };

  useEffect(() => {
    if (isNotEmpty && !isActive) {
      setIsActive(true);
    }

    if (isActiveDropDown) {
      setIsActive(true);
    }
  }, []);

  const onTouchStart = event => {
    if (handleTouchStart) {
      handleTouchStart(event);
    }
  };

  const onTouchEnd = event => {
    if (handleTouchEnd) {
      handleTouchEnd(event);
    }
  };

  const onMouseMove = () => {
    if (handleMouseMove) {
      handleMouseMove();
    }
  };

  const onMouseLeave = () => {
    if (handleMouseLeave) {
      handleMouseLeave();
    }
  };

  return (
    <div
      className={styles.dropdown}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onTouchStart={onTouchStart}
      onTouchEnd={onTouchEnd}
    >
      <div className={styles.head} tabIndex={-1} role="button" onClick={toggleDropDown}>
        <div>
          <Title isRequiredText={isRequiredText} isRequired={isRequired}>
            {title}
          </Title>
          {description && isActive && <span className={styles.description}>{description}</span>}
        </div>
        {isShowArrow && <DropdownIcon className={cn(styles.icon, iconClassName)} />}
      </div>
      {isActive && <div className={cn(styles.body, bodyClassName)}>{children}</div>}
    </div>
  );
};

export default Dropdown;
