/* eslint-disable */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "../feed/components/tabs";
import Filter from "./filter";
import Profiles from "./profiles";
import Organizations from "./organizations";
import Events from "./events";
import Jobs from "./jobs";
import { actions as homeActions } from "../../actions/home";
import styles from "./saved.module.css";
import { OverlayPreloader } from "../common/preloader";

const Saved = () => {
  const [filter, setFilter] = useState({});

  const all = { value: "All", label: "latest" };
  const profiles = { value: "Profiles", label: "profiles" };
  const organizations = { value: "Organizations", label: "organizations" };
  const jobs = { value: "Jobs", label: "jobs" };
  const events = { value: "Events", label: "events" };
  const dispatch = useDispatch();
  const { savedData, preloaders } = useSelector(state => state.home);
  const getSaved = useCallback(command => dispatch(homeActions.getSaved(command)), [dispatch]); // eslint-disable-line
  const addToFavorite = useCallback(listingId => dispatch(homeActions.addToFavorite(listingId)), [dispatch]); // eslint-disable-line
  const removeFavorite = useCallback(listingId => dispatch(homeActions.removeFavorite(listingId)), [dispatch]); // eslint-disable-line

  const filterOptions = [all, profiles, organizations, jobs, events];

  useEffect(() => {
    if (!filter.value) {
      setFilter(all);
    }
    getSaved();
  }, []);

  const handleChangeTab = value => {
    if (value.label !== filter.label) {
      setFilter(value);

      getSaved(value.label);
    }
  };

  return (
    <div className={styles.wrapper}>
      {preloaders.saved && <OverlayPreloader />}

      <Tabs />
      <Filter filter={filter} setFilter={handleChangeTab} filterOptions={filterOptions} />

      {(filter.value === profiles.value || filter.value === all.value) && (
        <Profiles
          onClick={() => handleChangeTab(profiles)}
          count={savedData?.count?.profiles}
          isActive={filter.value === profiles.value}
          handleClickAll={() => handleChangeTab(all)}
          list={savedData?.list?.profiles}
          removeFavorite={removeFavorite}
          addToFavorite={addToFavorite}
        />
      )}

      {(filter.value === organizations.value || filter.value === all.value) && (
        <Organizations
          onClick={() => handleChangeTab(organizations)}
          isActive={filter.value === organizations.value}
          count={savedData?.count?.organizations}
          handleClickAll={() => handleChangeTab(all)}
          list={savedData?.list?.organizations}
          removeFavorite={removeFavorite}
          addToFavorite={addToFavorite}
        />
      )}

      {(filter.value === jobs.value || filter.value === all.value) && (
        <Jobs
          onClick={() => handleChangeTab(jobs)}
          isActive={filter.value === jobs.value}
          handleClickAll={() => handleChangeTab(all)}
          count={savedData?.count?.jobs}
          list={savedData?.list?.jobs}
          removeFavorite={removeFavorite}
          addToFavorite={addToFavorite}
        />
      )}

      {(filter.value === events.value || filter.value === all.value) && (
        <Events
          onClick={() => handleChangeTab(events)}
          isActive={filter.value === events.value}
          handleClickAll={() => handleChangeTab(all)}
          list={savedData?.list?.events}
          count={savedData?.count?.events}
          removeFavorite={removeFavorite}
          addToFavorite={addToFavorite}
        />
      )}
    </div>
  );
};

export default Saved;
