import React from "react";
import cn from "classnames";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AddFreeListing as AddFreeListingContent } from "../../../../home/addFreeListing";
import { Modal } from "../../../../common/modal";
import { OverlayPreloader } from "../../../../common/preloader";
import styles from "./addFreeListing.module.css";

export const AddNewListing = ({ clearAllModals, closeModal }) => {
  const { preloaders } = useSelector(state => state.home);
  const history = useHistory();
  const isMyEventsPage = history.location.pathname.includes("/my-events");
  const isMyJobsPage = history.location.pathname.includes("/my-jobs");
  const isOrganizationsPage = history.location.pathname.includes("/my-organizations");
  const isEventsJobsOrOrganizations = isMyEventsPage || isMyJobsPage || isOrganizationsPage;
  const modalClassname = isEventsJobsOrOrganizations ? styles.modalHide : "";
  const buttonClassname = isEventsJobsOrOrganizations ? styles.button : "";

  return (
    <>
      {isEventsJobsOrOrganizations ? <OverlayPreloader /> : null}
      <Modal
        closeModal={closeModal}
        clearAllModals={clearAllModals}
        classNameMain={cn(styles.modal, modalClassname)}
        classNameButton={buttonClassname}
      >
        {preloaders.listingAssistant && !isEventsJobsOrOrganizations && <OverlayPreloader />}
        <AddFreeListingContent handleClose={closeModal} isModal />
      </Modal>
    </>
  );
};
