import React from "react";
import { useTranslation } from "react-i18next";
import cn from "classnames";

import { modals as modalsConstant } from "../../../constants/listings";
import { statuses } from "../../../constants/listingDetail";
import { Button } from "../../common/button";
import styles from "./profileInfo.module.css";

const ProfileInfo = ({ title, openModal, isPremium, publishStatus, changeStatus, isDraft, children, canceledDate }) => {
  const isExpired = publishStatus === statuses.expired;
  const { t } = useTranslation();

  const renderText = () => {
    if (isExpired && isPremium) {
      return (
        <Button className={cn("button--clear", styles.renew)} onClick={() => openModal(modalsConstant.upgrade)}>
          - {t("clicktorenew")}
        </Button>
      );
    }

    if (isPremium && !canceledDate) {
      return (
        <Button className={cn("button--clear", styles.renew)} onClick={() => openModal(modalsConstant.downgrade)}>
          {t("downgrade")}
        </Button>
      );
    }

    if (isPremium && !canceledDate) {
      return (
        <Button className={cn("button--clear", styles.renew)} onClick={() => openModal(modalsConstant.downgrade)}>
          {t("downgrade")}
        </Button>
      );
    }

    return null;
  };

  return (
    <div className={styles.productDetails}>
      <div className={styles.flexTop}>
        <h1 className={cn(styles.title, styles.titleText)}>{title}</h1>

        <div className={styles.statusContent}>{renderText()}</div>
      </div>
      <div className={styles.flexContent}>
        <p className={styles.info}>{isDraft ? "Unpublished changes in draft" : <>Changes {changeStatus}</>}</p>
        {children}
      </div>
    </div>
  );
};

export default ProfileInfo;
