import queryString from "query-string";

import { listing, payment } from "../constants/actionTypes";

const defaultSuccess = { addMembership: null, deleteMembership: null, downgradeSuccess: null, submitChanges: null };

const defaultModals = {
  addCard: false,
  editCard: false,
  upgrade: false,
  payPending: false,
  renew: false,
  payment: false,
  paymentSuccess: false,
  name: false,
  photo: false,
  socialPhoto: false,
  phone: false,
  media: false,
  practiceArea: false,
  languages: false,
  bio: false,
  editLawFirm: false,
  lawFirmLocation: false,
  license: false,
  education: false,
  association: false,
  avvoReviews: false,
  yelpReviews: false,
  verification: false,
  verificationSuccess: false,
  listingBasics: false,
  insurances: false,
  confirm: false,
  delete: false,
  deleteSuccess: false,
  downgradePeriod: false,
  downgradePeriodSuccess: false,
  downgrade: false,
  downgradeSuccess: false,
  pause: false,
  pauseSuccess: false,
  unpause: false,
  unpauseSuccess: false,
  addPhone: false,
  addEmail: false,
  addPhoneSuccess: false,
  addEmailSuccess: false,
  deleteEmail: false,
  deleteEmailSuccess: false,
  deletePhone: false,
  deletePhoneSuccess: false,
  addNewListing: false
};

const defaultState = {
  directoryPractices: [],
  listingAssistants: [],
  timeZones: [],
  directoriesProfessions: {},
  practices: [],
  error: null,
  expand: "",
  memberData: {
    added: null,
    associations: [],
    insurances: [],
    attorney: null,
    description: null,
    educations: [],
    is_premium: null,
    licenses: [],
    listing_url: null,
    lawfirms: [],
    languages: [],
    verifiedphones: [],
    verifiedemails: [],
    event_schedules: [],
    communities: [],
    event_tickets: [],
    yelp_disabled: "0",
    avvo_disabled: "0",
    phone: null,
    title: null,
    socials: [
      { social_name: "linkedin", social_url: "" },
      { social_name: "linkedinCompany", social_url: "" },
      { social_name: "facebook", social_url: "" },
      { social_name: "twitter", social_url: "" },
      { social_name: "instagram", social_url: "" },
      { social_name: "rss", social_url: "" },
      { social_name: "youtube", social_url: "" },
      { social_name: "yelp", social_url: "" }
    ],
    updated: null,
    website: null
  },
  memberships: [],
  filters: {
    directories: [],
    freesponsor: [],
    professiongroups: []
  },
  preloaders: {
    addMembership: null,
    deleteMembership: null,
    downgrade: null,
    downgradePeriod: null,
    memberships: null,
    upgradeOptions: null,
    avvo: false,
    association: false,
    education: false,
    licenses: false,
    memberData: null,
    modal: false,
    newMemberships: null,
    notificationEmail: null,
    yelp: false,
    photo: false,
    notifications: false,
    confirm: false,
    pause: false,
    unpause: false,
    listingAssistant: false,
    listingConfig: false,
    socialImage: false,
    profile: true
  },
  socialGroups: [],
  insuranceGroups: {
    companies: [],
    networks: [],
    plans: []
  },
  success: defaultSuccess,
  images: [],
  photoError: null,
  upgradeOptions: [],
  showUpgradeOptionsError: false,
  validations: [],
  activeUpgradeOptions: {},
  notificationEmail: {
    isError: false,
    isErrorVerification: false,
    showVerification: false,
    email: ""
  },
  notificationPhone: {
    isError: false,
    isErrorVerification: false,
    showVerification: false,
    phone: ""
  },
  pagination: {
    currentPage: null,
    currentPageNum: null,
    lastPage: null,
    lastPageNum: null
  },
  modals: {
    ...defaultModals
  },
  savedPaymentData: {
    isActive: false
  },
  notificationModalData: "",
  languagesList: [],
  professions: [],
  listingConfig: {
    eventsProfession: "",
    jobsProfession: "",
    profilesForm: "",
    profilesProfession: ""
  }
};

export default function(state = defaultState, action) {
  let newListings;
  const { payload, type } = action;
  switch (type) {
    case listing.SET_LOADING:
      return {
        ...state,
        preloaders: { ...state.preloaders, [payload.section]: payload.isSectionLoading }
      };

    case listing.GET_MEMBER_DATA: {
      return {
        ...state,
        memberData: payload === null ? defaultState : payload.data,
        success: { ...state.success, submitChanges: payload.successMessage },
        preloaders: {
          ...state.preloaders,
          licenses: false,
          educations: false,
          associations: false,
          avvo: false,
          yelp: false,
          modal: false,
          memberData: false,
          notifications: false,
          profile: false
        },
        modals: {
          ...defaultModals,
          practiceArea: state.modals.practiceArea,
          languages: state.modals.languages,
          photo: state.modals.photo,
          paymentSuccess: state.modals.paymentSuccess,
          addPhoneSuccess: state.modals.addPhoneSuccess,
          addEmailSuccess: state.modals.addEmailSuccess,
          deleteEmailSuccess: state.modals.deleteEmailSuccess,
          deletePhoneSuccess: state.modals.deletePhoneSuccess,
          media: false
        },
        notificationEmail: {
          ...state.notificationEmail,
          showVerification: false,
          email: ""
        },
        notificationPhone: {
          ...state.notificationPhone,
          showVerification: false,
          phone: ""
        },
        validations: payload.help ? payload.help.validations : state.validations,
        images: payload.help ? payload.help.images || [] : state.images,
        socialGroups: payload.help ? payload.help.directory_socials : state.socialGroups,
        directoryPractices: payload.help ? Object.values(payload.help.practices) : state.directoryPractices,
        languagesList: payload.help ? Object.values(payload.help.languages) : state.languagesList,
        insuranceGroups: payload.help
          ? {
              companies: payload.help.insurances_companies,
              networks: payload.help.insurances_networks,
              plans: payload.help.insurances_plans
            }
          : state.insuranceGroups
      };
    }

    case listing.SUBMIT_SUCCESS_REVIEWS: {
      return {
        ...state,
        memberData: {
          ...payload.memberData,
          [`${payload.review}_disabled`]: payload.active
        },
        success: { ...state.success, submitChanges: payload.successMessage },
        preloaders: {
          ...state.preloaders,
          [payload.review]: false
        }
      };
    }

    case listing.GET_VALIDATIONS: {
      return {
        ...state,
        expand: payload
      };
    }

    case listing.GET_MEMBER_DATA_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, memberData: false }
      };
    }

    case listing.GET_INSURANCE_GROUPS_FAIL: {
      return {
        ...state,
        preloaders: { ...state.preloaders, insurances: false }
      };
    }

    case listing.SUBMIT_CHANGES_SUCCESS: {
      return {
        ...state,
        success: { ...state.success, submitChanges: payload },
        preloaders: {
          ...state.preloaders,
          licenses: false,
          educations: false,
          associations: false,
          avvo: false,
          yelp: false,
          modal: false,
          memberData: false
        },
        modals: {
          ...defaultModals
        }
      };
    }
    case listing.SUBMIT_CHANGES_CLOSE_MODALS: {
      return {
        ...state,
        modals: {
          ...defaultModals
        }
      };
    }

    case listing.PUBLISH_LISTING: {
      return {
        ...state,
        success: { ...state.success, submitChanges: payload },
        preloaders: {
          ...state.preloaders,
          memberData: false
        },
        memberData: {
          ...state.memberData,
          is_draft: payload?.is_draft,
          status: payload?.status,
          listing_id: payload?.listing_id
        }
      };
    }

    case listing.SET_MEMBERSHIP_DATA_CLEAR:
      return {
        ...defaultState
      };

    case listing.CHANGE_MEMBER_DATA_YELP_AVVO_SUCCESS:
      return {
        ...state
      };

    case listing.PUBLISH_LISTING_FAIL:
    case listing.SUBMIT_CHANGES_FAIL:
      return {
        ...state,
        error: payload,
        preloaders: {
          ...state.preloaders,
          licenses: false,
          educations: false,
          associations: false,
          avvo: false,
          yelp: false,
          modal: false,
          memberData: false,
          notifications: false
        }
      };

    case listing.CLEAR_CHANGES_FIELD:
      return {
        ...state,
        success: defaultSuccess
      };

    case listing.UPLOAD_IMAGE: {
      return {
        ...state,
        images: [payload],
        preloaders: {
          ...state.preloaders,
          photo: false
        }
      };
    }

    case listing.UPLOAD_SOCIAL_IMAGE: {
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          socialImage: false
        },
        memberData: {
          ...state.memberData,
          listings_smp_cf_image_url: payload
        }
      };
    }

    case listing.DELETE_IMAGE_FAIL:
    case listing.UPLOAD_SOCIAL_IMAGE_FAIL:
    case listing.UPLOAD_IMAGE_FAIL: {
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          photo: false
        },
        photoError: payload
      };
    }

    case listing.DELETE_IMAGE: {
      return {
        ...state,
        memberData: {
          ...state.memberData,
          images: state.memberData.images.filter(item => String(item.image_id) !== String(payload))
        },
        preloaders: {
          ...state.preloaders,
          photo: false
        }
      };
    }

    case listing.SET_SUCCESS:
      return {
        ...state,
        success: { ...state.success, [payload.action]: payload.isActionSuccessful }
      };

    case listing.GET_MEMBERSHIPS:
      // eslint-disable-next-line no-case-declarations
      const lastPageNum = payload.isEvensOrJobsPage
        ? payload.lastPage
        : parseInt(queryString.parse(queryString.extract(payload.lastPage)).page, 10);
      // eslint-disable-next-line no-case-declarations
      const currentPageNum = payload.isEvensOrJobsPage
        ? payload.currentPage
        : parseInt(queryString.parse(queryString.extract(payload.currentPage)).page, 10);

      return {
        ...state,
        memberships: payload.listings,
        filters: payload.filters,
        preloaders: { ...state.preloaders, memberships: false, deleteMembership: false },
        pagination: { currentPage: payload.currentPage, currentPageNum, lastPage: payload.lastPage, lastPageNum, perPage: payload.perPage }
      };
    /*

    case listing.GET_MEMBERSHIPS:
      // eslint-disable-next-line no-case-declarations
      const lastPageNum = payload.isEvensOrJobsPage
        ? payload.lastPage
        : parseInt(queryString.parse(queryString.extract(payload.lastPage)).page, 10);
      // eslint-disable-next-line no-case-declarations
      const currentPageNum = payload.isEvensOrJobsPage
        ? payload.currentPage
        : parseInt(queryString.parse(queryString.extract(payload.currentPage)).page, 10);

      return {
        ...state,
        memberships: payload.listings,
        filters: payload.filters,
        preloaders: { ...state.preloaders, memberships: false, deleteMembership: false },
        pagination: { currentPage: payload.currentPage, currentPageNum, lastPage: payload.lastPage, lastPageNum, perPage: payload.perPage }
      };
    */

    case listing.DELETE_SUCCESS: {
      const removedIndex = state.memberships.findIndex(({ listing_id }) => listing_id === payload);
      const newMemberships = [...state.memberships];
      newMemberships.splice(removedIndex, 1);

      return {
        ...state,
        memberships: newMemberships,
        modals: {
          ...defaultModals,
          delete: false,
          deleteSuccess: true
        },
        preloaders: {
          ...state.preloaders,
          deleteMembership: false
        }
      };
    }

    case listing.DELETE_FAIL:
      return {
        ...state,
        error: payload,
        preloaders: {
          ...state.preloaders,
          deleteMembership: false
        }
      };
    case listing.DOWNGRADE_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultModals,
          downgrade: false,
          downgradePeriod: false,
          downgradeSuccess: true
        },
        preloaders: {
          ...state.preloaders,
          downgrade: false
        }
      };

    case listing.UN_DOWNGRADE_SUCCESS:
      return {
        ...state,
        modals: {
          ...defaultModals,
          downgradePeriod: false,
          downgradePeriodSuccess: true
        },
        preloaders: {
          ...state.preloaders,
          downgrade: false
        }
      };

    case listing.UN_DOWNGRADE_FAIL:
      return {
        ...state,
        error: payload,
        preloaders: {
          ...state.preloaders,
          downgrade: false
        }
      };

    case listing.DOWNGRADE_FAIL:
      return {
        ...state,
        error: payload,
        preloaders: {
          ...state.preloaders,
          downgrade: false
        }
      };

    case listing.PAUSE_SUCCESS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          pause: false
        },
        modals: {
          ...state.modals,
          pause: false,
          pauseSuccess: true
        }
      };

    case listing.UNPAUSE_SUCCESS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          unpause: false
        },
        modals: {
          ...state.modals,
          unpause: false,
          unpauseSuccess: true
        }
      };

    case listing.PAUSE_FAIL:
      return {
        ...state,
        error: payload,
        preloaders: {
          ...state.preloaders,
          pause: false
        }
      };

    case listing.UNPAUSE_FAIL:
      return {
        ...state,
        error: payload,
        preloaders: {
          ...state.preloaders,
          unpause: false
        }
      };

    case listing.SET_MEMBERSHIPS_CLEAR:
      return {
        ...state,
        memberships: []
      };

    case listing.GET_UPGRADE_OPTIONS:
      return {
        ...state,
        upgradeOptions: payload,
        activeUpgradeOptions: payload[0],
        preloaders: { ...state.preloaders, upgradeOptions: false },
        showUpgradeOptionsError: false
      };

    case listing.GET_UPGRADE_OPTIONS_SHOW_ERROR:
      return {
        ...state,
        showUpgradeOptionsError: true
      };

    case listing.GET_UPGRADE_OPTIONS_HIDE_ERROR:
      return {
        ...state,
        showUpgradeOptionsError: false
      };

    case listing.UPDATE_ACTIVE_UPGRADE_OPTIONS:
      return {
        ...state,
        activeUpgradeOptions: payload
      };

    case listing.UPGRADE_CLEAN:
      return {
        ...state,
        upgradeOptions: []
      };

    case listing.CLEAR_ERRORS:
      return {
        ...state,
        error: null
      };

    case listing.GET_UPGRADE_OPTIONS_FAIL:
      return {
        ...state,
        error: payload || "Oops! Something went wrong",
        preloaders: { ...state.preloaders, upgradeOptions: false }
      };

    case listing.UPGRADE:
      return {
        ...state
      };

    case listing.UPGRADE_SUCCESS:
      return {
        ...state
      };

    case listing.UPGRADE_FAIL:
      return {
        ...state
      };

    case listing.DOWNGRADE:
      return {
        ...state
      };

    case listing.DOWNGRADE_LOCALLY: {
      return {
        ...state
      };
    }

    case payment.CHARGE_FOR_MEMBERSHIP_SUCCESS:
      newListings = state.memberships.map(item => {
        if (Number(item.listing_id) === Number(payload)) {
          return { ...item, is_premium: true, is_free: false, product_name: item.product_name.replace("Free", "Sponsor"), upgrade: "0" };
        }

        return item;
      });

      return {
        ...state,
        memberships: newListings,
        memberData: {
          ...state.memberData,
          is_premium: true,
          is_free: false,
          product_name: state.memberData?.product_name?.replace("Free", "Sponsor"),
          upgrade: "0"
        }
      };
    case listing.SUBMIT_PHONE_EMAIL_SUCCESS:
      return {
        ...state,
        modals: {
          ...state.modals,
          deleteEmail: false,
          deletePhone: false,
          [payload]: true
        },
        notificationModalData: ""
      };
    case listing.TOGGLE_ACTIVITY_OF_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: payload.isActive
        },
        notificationModalData: ""
      };
    case listing.ACTIVE_NOTIFICATION_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [payload.modal]: true
        },
        notificationModalData: payload.data
      };
    case listing.CLEAR_ALL_MODALS:
      return {
        ...state,
        modals: {
          ...defaultModals
        }
      };
    case listing.NOTIFICATIONS_LOADING:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: payload
        },
        notificationEmail: {
          ...state.notificationEmail,
          isError: false,
          isErrorVerification: false,
          email: ""
        },
        notificationPhone: {
          ...state.notificationPhone,
          isError: false,
          isErrorVerification: false,
          phone: ""
        }
      };
    case listing.NOTIFICATION_EMAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        notificationEmail: {
          ...state.notificationEmail,
          showVerification: true,
          email: payload.email
        }
      };
    case listing.NOTIFICATION_PHONE:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        notificationPhone: {
          ...state.notificationPhone,
          showVerification: true,
          phone: payload.phone
        }
      };

    case listing.NOTIFICATION_EMAIL_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        notificationEmail: {
          ...state.notificationEmail,
          isError: payload
        }
      };
    case listing.NOTIFICATION_PHONE_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        notificationPhone: {
          ...state.notificationPhone,
          isError: payload
        }
      };
    case listing.NOTIFICATION_VERIFICATION_EMAIL_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        notificationEmail: {
          ...state.notificationEmail,
          isErrorVerification: true
        }
      };
    case listing.NOTIFICATION_VERIFICATION_PHONE_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        notificationPhone: {
          ...state.notificationPhone,
          isErrorVerification: true
        }
      };
    case listing.NOTIFICATION_VERIFICATION_PHONE_SUCCESS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        modals: {
          ...defaultModals,
          addPhone: false,
          addPhoneSuccess: true
        }
      };
    case listing.NOTIFICATION_VERIFICATION_EMAIL_SUCCESS:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          notifications: false
        },
        modals: {
          ...defaultModals,
          addEmail: false,
          addEmailSuccess: true
        }
      };
    case listing.SET_SAVED_PAYMENT_DATA:
      return {
        ...state,
        savedPaymentData: {
          isActive: true
        }
      };
    case listing.CLEAR_SAVED_PAYMENT_DATA:
      return {
        ...state,
        savedPaymentData: {
          isActive: false
        }
      };

    case listing.GET_LISTING_ASSISTANT:
      return {
        ...state,
        listingAssistants: payload || []
      };

    case listing.HIDE_LISTING_ASSISTANT:
      return {
        ...state,
        listingAssistants: state.listingAssistants.filter(item => String(item.listing_assistant_id) !== String(payload)),
        preloaders: {
          ...state.preloaders,
          listingAssistant: false
        }
      };

    case listing.HIDE_LISTING_ASSISTANT_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          listingAssistant: false
        },
        error: payload
      };

    case listing.GET_LISTINGS_CONFIG:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          listingConfig: false
        },
        listingConfig: payload
      };

    case listing.GET_LISTINGS_CONFIG_FAIL:
      return {
        ...state,
        preloaders: {
          ...state.preloaders,
          listingConfig: false
        },
        error: payload
      };

    case listing.GET_PRACTICES:
      return {
        ...state,
        practices: payload
      };

    case listing.GET_PRACTICES_FAIL:
      return {
        ...state,
        error: payload
      };

    case listing.GET_TIME_ZONES:
      return {
        ...state,
        timeZones: payload
      };

    case listing.GET_DIRECTORIES_PROFESSIONS:
      return {
        ...state,
        directoriesProfessions: payload
      };

    default:
      return {
        ...state
      };
  }
}
