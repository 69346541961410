import React, { useCallback } from "react";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ReactComponent as HomeIcon } from "../../media/icons/sidebar/home.svg";
import { ReactComponent as InboxIcon } from "../../media/icons/sidebar/inbox.svg";
import { ReactComponent as ReferralsIcon } from "../../media/icons/sidebar/referrals.svg";
import { ReactComponent as QAIcon } from "../../media/icons/sidebar/q&a.svg";
/* todo: removupe commented code */
// import { ReactComponent as ListingsIcon } from "../../media/icons/sidebar/listings.svg";
// import { ReactComponent as AccountIcon } from "../../media/icons/sidebar/account.svg";
// import { ReactComponent as AnalyticsIcon } from "../../media/icons/sidebar/analytics.svg";
// import { ReactComponent as TeamsIcon } from "../../media/icons/sidebar/teams.svg";
// import { ReactComponent as ProfileIcon } from "../../media/icons/sidebar/profile.svg";
// import { ReactComponent as EventsIcon } from "../../media/icons/sidebar/events.svg";
// import { ReactComponent as OrganizationsIcon } from "../../media/icons/sidebar/organizations.svg";
// import { ReactComponent as JobsIcon } from "../../media/icons/sidebar/jobs.svg";
// import { ReactComponent as NotificationsIcon } from "../../media/icons/sidebar/notifications.svg";
// import { ReactComponent as PaymentsIcon } from "../../media/icons/sidebar/payments.svg";
import { actions as mainActions } from "../../actions/main";
import { Logo } from "../header/logo";
import styles from "./mainSidebar.module.css";
import { actions as teamsActions } from "../../actions/teams";

// keycloak
const MainSidebar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isActiveMobileMenu } = useSelector(state => state.main);
  const {
    globalInfo: { new_messages: newMessages }
  } = useSelector(state => state.main);
  const toggleMobileMenu = useCallback(isActive => dispatch(mainActions.toggleMobileMenu(isActive)), [dispatch]);
  const setCurrentTeam = useCallback(team => dispatch(teamsActions.setCurrentTeam(team)), [dispatch]);

  const location = useLocation();
  const isShort = location.pathname === "/inbox";
  const isSavedPage = location.pathname === "/saved";
  const activeSidebar = isActiveMobileMenu ? styles.sidebarActive : "";
  const overlaySidebar = isActiveMobileMenu ? styles.overlayActive : "";
  const shortSidebar = isShort ? styles.shortSidebar : "";

  const handleCloseSidebar = () => {
    if (isActiveMobileMenu) {
      toggleMobileMenu(false);
    }

    setCurrentTeam({ value: "" });
  };

  return (
    <>
      <div className={cn(styles.sidebar, activeSidebar, shortSidebar)}>
        <div className={styles.logo} onClick={handleCloseSidebar} aria-label="close" role="button" tabIndex="0">
          <Logo />
        </div>

        <NavLink
          className={cn(styles.link, isSavedPage ? styles.activeLink : "")}
          exact
          to="/"
          activeClassName={styles.activeLink}
          onClick={handleCloseSidebar}
        >
          <HomeIcon />
          My Feed
        </NavLink>

        {/*
        <NavLink className={styles.link} exact to="/" activeClassName={styles.activeLink} onClick={handleCloseSidebar}>
          <HomeIcon />
          {t("dashboard_header_nav_home")}
        </NavLink>
        */}

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/inbox" onClick={handleCloseSidebar}>
          <InboxIcon />
          {t("dashboard_header_nav_inbox")}

          <span className={cn(styles.inbox, Number(newMessages) > 0 ? styles.inboxActive : "")}>{newMessages}</span>
        </NavLink>
        {/*
        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/profile" onClick={handleCloseSidebar}>
          <ProfileIcon />
          My Profile
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/listings?page=0" onClick={handleCloseSidebar}>
          <ListingsIcon />
          {t("dashboard_header_nav_listings")}
        </NavLink>


        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-organizations?page=0" onClick={handleCloseSidebar}>
          <OrganizationsIcon />
          Organizations
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-events?page=0" onClick={handleCloseSidebar}>
          <EventsIcon />
          Events
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/my-jobs?page=0" onClick={handleCloseSidebar}>
          <JobsIcon />
          Jobs
        </NavLink>
        */}

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/referrals" onClick={handleCloseSidebar}>
          <ReferralsIcon />
          {t("dashboard_header_nav_leads")}
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/question-answer" onClick={handleCloseSidebar}>
          <QAIcon />
          {t("dashboard_header_nav_qa")}
        </NavLink>
        {/*
        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/analytics" onClick={handleCloseSidebar}>
          <AnalyticsIcon />
          Analytics
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/account" onClick={handleCloseSidebar}>
          <AccountIcon />
          My Account
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/account" onClick={handleCloseSidebar}>
          <AccountIcon />
          {t("dashboard_nav_myaccount")}
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/teams" onClick={handleCloseSidebar}>
          <TeamsIcon />
          My Teams
        </NavLink>

        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/manage-notification" onClick={handleCloseSidebar}>
          <NotificationsIcon />
          {t("dashboard_nav_notifications")}
        </NavLink>
       */}

        {/*
        <NavLink activeClassName={styles.activeLink} className={styles.link} to="/payments" onClick={handleCloseSidebar}>
          <PaymentsIcon />
          Payments
        </NavLink>

        <button type="button" className={styles.logout} onClick={() => keycloak.logout({ redirectUri: `${window.location.origin}/login` })}>
          <LogooutIcon />
          Log Out
        </button>
        */}
      </div>

      <div aria-label="close" role="button" tabIndex="0" className={cn(styles.overlay, overlaySidebar)} onClick={handleCloseSidebar} />
    </>
  );
};

export default MainSidebar;
