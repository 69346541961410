import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Tabs from "./components/tabs";
import Filter from "../saved/filter";
import Profiles from "../saved/profiles";
import Organizations from "../saved/organizations";
import Events from "../saved/events";
import Jobs from "../saved/jobs";
import FollowingModal from "./components/followingModal";
import { ReactComponent as SearchIcon } from "../../media/icons/search.svg";
import { ReactComponent as PencilIcon } from "../../media/icons/edit-pen-white.svg";
import styles from "./feed.module.css";
import { actions as homeActions } from "../../actions/home";
import { OverlayPreloader } from "../common/preloader";

const Feed = () => {
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({});
  const [search, setSearch] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const getMyFeed = useCallback((command, search) => dispatch(homeActions.getMyFeed(command, search)), [dispatch]); // eslint-disable-line
  const { myFeed, preloaders, followings } = useSelector(state => state.home);
  const addToFavorite = useCallback(listingId => dispatch(homeActions.addToFavorite(listingId)), [dispatch]); // eslint-disable-line
  const removeFavorite = useCallback(listingId => dispatch(homeActions.removeFavorite(listingId)), [dispatch]); // eslint-disable-line
  const getFollowing = useCallback(() => dispatch(homeActions.getFollowing()), [dispatch]); // eslint-disable-line
  const removeFollowing = useCallback(id => dispatch(homeActions.removeFollowing(id)), [dispatch]); // eslint-disable-line

  const all = { value: "All", label: "all" };
  const profiles = { value: "Profiles", label: "profiles" };
  const organizations = { value: "Organizations", label: "organizations" };
  const jobs = { value: "Jobs", label: "jobs" };
  const events = { value: "Events", label: "events" };

  const filterOptions = [all, profiles, organizations, jobs, events];

  useEffect(() => {
    if (!filter.value) {
      setFilter(all);
    }

    getMyFeed();
    getFollowing();
  }, []);

  const handleShowModal = () => {
    setIsShowModal(true);
  };

  const handleHideModal = () => {
    setIsShowModal(false);
  };

  const handleChangeTab = value => {
    if (value.label !== filter.label) {
      setFilter(value);

      getMyFeed(value.label);
    }
  };

  const count = useMemo(() => {
    if ("profiles" in myFeed.count) {
      return myFeed.count.profiles + myFeed.count.events + myFeed.count.jobs + myFeed.count.organizations;
    }

    return 0;
  }, [myFeed.count]);

  const handleChange = event => {
    setSearch(event.target.value);

    if (!isEdited) {
      setIsEdited(true);
    }
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (isEdited) {
        getMyFeed(filter.label, search);
      }
    }, 750);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [search]);

  return (
    <div className={styles.wrapper}>
      {preloaders.feed && <OverlayPreloader />}

      {isShowModal && (
        <FollowingModal
          preloader={preloaders.removeFollowing}
          followings={followings}
          removeFollowing={removeFollowing}
          onClose={handleHideModal}
        />
      )}
      <Tabs />

      <div className={styles.searchContent}>
        <SearchIcon className={styles.searchIcon} />
        <input type="text" className={styles.search} value={search} onChange={handleChange} />
        <button type="button" className={styles.reset} onClick={() => setSearch("")}>
          Reset
        </button>
      </div>

      <Filter filter={filter} setFilter={handleChangeTab} filterOptions={filterOptions} />

      <div className={styles.flex}>
        {followings.length ? (
          <div className={styles.following}>
            <div className={styles.followingHeading}>
              <h6 className={styles.followingTitle}>Following ({followings.length})</h6>
              <button type="button" className={styles.followingEdit} onClick={handleShowModal}>
                <PencilIcon />
              </button>
            </div>

            <div className={styles.followingList}>
              {followings.map(item => {
                return (
                  <button key={item.name} type="button" className={styles.followingItem}>
                    {item.name}
                  </button>
                );
              })}
            </div>
          </div>
        ) : null}
        <div className={styles.content}>
          <h1 className={styles.title}>Shows results: {count ? <span>{count}</span> : null}</h1>

          {(filter.value === profiles.value || filter.value === all.value) && (
            <Profiles
              isExpand
              onClick={() => handleChangeTab(profiles)}
              isActive={filter.value === profiles.value}
              handleClickAll={() => handleChangeTab(all)}
              count={myFeed?.count?.profiles}
              list={myFeed?.list?.profiles}
              removeFavorite={removeFavorite}
              addToFavorite={addToFavorite}
            />
          )}

          {(filter.value === organizations.value || filter.value === all.value) && (
            <Organizations
              isExpand
              onClick={() => handleChangeTab(organizations)}
              isActive={filter.value === organizations.value}
              handleClickAll={() => handleChangeTab(all)}
              count={myFeed?.count?.organizations}
              list={myFeed?.list?.organizations}
              removeFavorite={removeFavorite}
              addToFavorite={addToFavorite}
            />
          )}

          {(filter.value === jobs.value || filter.value === all.value) && (
            <Jobs
              isExpand
              onClick={() => handleChangeTab(jobs)}
              isActive={filter.value === jobs.value}
              handleClickAll={() => handleChangeTab(all)}
              organizations
              count={myFeed?.count?.jobs}
              list={myFeed?.list?.jobs}
              removeFavorite={removeFavorite}
              addToFavorite={addToFavorite}
            />
          )}

          {(filter.value === events.value || filter.value === all.value) && (
            <Events
              isExpand
              onClick={() => handleChangeTab(events)}
              isActive={filter.value === events.value}
              handleClickAll={() => handleChangeTab(all)}
              organizations
              count={myFeed?.count?.events}
              list={myFeed?.list?.events}
              removeFavorite={removeFavorite}
              addToFavorite={addToFavorite}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Feed;
