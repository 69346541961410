import React from "react";
import cn from "classnames";

import styles from "./profileActions.module.css";

const ProfileActions = ({
  isBottomPosition,
  isNotFilledAllFields,
  publishListing,
  setShowRequiredText,
  listingId,
  isDraft,
  showRequiredText,
  resetDraft,
  parentId
}) => {
  const bottomPositionClassName = isBottomPosition ? styles.productActionsBottom : "";

  const renderPublishButton = () => {
    if (!isDraft) {
      return (
        <button type="button" className={styles.primary} disabled>
          Publish Draft
        </button>
      );
    }

    if (isNotFilledAllFields) {
      return (
        <button type="button" className={styles.primary} onClick={() => setShowRequiredText(true)}>
          Publish Draft
        </button>
      );
    }

    return (
      <button type="button" className={styles.primary} onClick={() => publishListing(listingId)}>
        Publish Draft
      </button>
    );
  };

  const handleRemoveDraft = () => {
    resetDraft(listingId);
  };

  return (
    <>
      <div className={cn(styles.productActions, bottomPositionClassName)}>
        {renderPublishButton()}

        {/*
        <button type="button" className={styles.preview}>
          Preview Profile
        </button>
        */}

        {Boolean(parentId) && isDraft ? (
          <button type="button" className={styles.secondary} onClick={handleRemoveDraft}>
            Delete Draft
          </button>
        ) : null}
      </div>
      {isNotFilledAllFields && showRequiredText && <p className={styles.validationError}>Please add required fields before submitting</p>}
    </>
  );
};

export default ProfileActions;
